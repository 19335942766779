// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails ')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// import "@hotwired/turbo-rails"
// import "../controllers"
// Example: Import a stylesheet in app/frontend/index.css
// import '../../assets/stylesheets/ninaut.sass'
// import "../application"

// import.meta.globEager('../../assets/stylesheets/*.css')


// import("@vendor/core/jquery.min")
// import("@vendor/core/popper.min")

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

// import("@vendor/soft-ui-dashboard.min.js")

import 'datatables.net-bs5';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedheader-bs5';
import { Calendar } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

// Menu Active
    var pathname = $(location).attr('pathname')

    if (pathname.includes('/admin/users')) {
      $('#link-users').addClass('active')  
    } else if(pathname.includes('/admin/clients')){
      $('#link-clients').addClass('active')  
    } else if(pathname.includes('/admin/clients')){
      $('#link-clients').addClass('active')  
    } else if(pathname.includes('/admin/events')){
      $('#link-events').addClass('active')  
    } else if(pathname.includes('/admin/imports')){
      $('#link-imports').addClass('active')  
    } else if(pathname.includes('/admin/reports')){
      $('#link-reports').addClass('active')  
    }else{
      $('#link-dashboard').addClass('active')  
    }


    // Notification
    window.notification = function(msg, alert){
      // let msg = '<strong>Success!</strong> This is a success alert—check it out!'
      $('.toast-container').css('position', 'fixed');
      $('.toast-body').html(msg);
      $('.toast').removeClass().addClass('toast align-items-center text-bg-primary border-0').addClass(`alert-${alert}`)
      $('.toast').show()
  }

  // Select
 
  //  Editor de texto

  // window.quill = Quill
  const currentDateTime = () => {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOString = new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, -1);
  
    // convert to YYYY-MM-DDTHH:MM
    const datetimeInputString = localISOString.substring(
      0,
      ((localISOString.indexOf("T") | 0) + 6) | 0
    );
    // console.log(datetimeInputString);
    return datetimeInputString;
  };

  $('#datetime-local-input').val(currentDateTime())
  $('#datetime-local-input').attr('min',currentDateTime())
  

    // scrolll
    var Scrollbar = window.Scrollbar;
    var win = navigator.platform.indexOf('Win') > -1;
    if (win && document.querySelector('#sidenav-scrollbar')) {
      var options = {
        damping: '0.5'
      }
      Scrollbar.init(document.querySelector('#sidenav-scrollbar'), options);
    }


  
    var is_root = location.pathname == "/";
    if (is_root){
      var calendar = new Calendar(document.getElementById("calendar"), {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
  
        initialView: "dayGridMonth",
        locale: 'pt-BR',
        headerToolbar: {
          start: 'title', // will normally be on the left. if RTL, will be on the right
          center: '',
          end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
        },
        
        selectable: true,
        editable: false,
        initialDate: currentDateTime(),
        events: gon.calendar,
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric"
            }
          },
          agendaWeek: {
            titleFormat: {
              month: "long",
              year: "numeric",
              day: "numeric"
            }
          },
          agendaDay: {
            titleFormat: {
              month: "short",
              year: "numeric",
              day: "numeric"
            }
          }
        },
      });
  
      calendar.render();
    }
    